import React, { useEffect } from "react";
//router
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//redux
import store from "../store";
import { Provider } from "react-redux";
//components
import Footer from "./Footer";
import NavBar from "./NavBar";
import Home from "../Pages/Home/Home";
import Contact from "../Pages/Contact/Contact";
import AboutPage from "../Pages/About/AboutPage";
import NewsBrzdace from "../Pages/News/NewsPageBrzdace";
import NewsWakacje from "../Pages/News/NewsPageRoztanczoneWakacje";
import NewsZaproszenie from "../Pages/News/NewsPageZaproszenie";
import NewsNaturalCup from "../Pages/News/NewsPageNaturalCup";
import NewsMistrzostwa from "../Pages/News/NewsPageMistrzostwa";
import Offer from "../Pages/Offer/OfferPage";
import SignUpForm from "../Pages/SignUpForm/SignUpForm";
import Gallery from "../Pages/Gallery/GalleryPage";
import Messenger from "./utils/Messenger";

const MDanceApp: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <NavBar />
        <Messenger />
        <div className="appContainer">
          <Switch>
            {/* NEWS */}
            <Route path="/news/naturalcup">
              <NewsNaturalCup />
            </Route>
            <Route path="/news/mistrzostwa">
              <NewsMistrzostwa />
            </Route>
            <Route path="/news/zaproszenie">
              <NewsZaproszenie />
            </Route>
            <Route path="/news/brzdace">
              <NewsBrzdace />
            </Route>
            <Route path="/news/wakacje">
              <NewsWakacje />
            </Route>
            {/* NEWS */}
            <Route path="/galeria">
              <Gallery />
            </Route>
            <Route path="/oferta">
              <Offer />
            </Route>
            <Route path="/kontakt">
              <Contact />
            </Route>
            <Route path="/onas">
              <AboutPage />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
        <Footer />
      </Router>
    </Provider>
  );
};

export default MDanceApp;
