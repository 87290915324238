import React, { useEffect, useState } from 'react';
//assets
import picMobile from '../../assets/offer/offer-header-1.jpg';
import picHero from '../../assets/offer/offer-header-2.jpg';
//material ui
import Brightness1OutlinedIcon from '@material-ui/icons/Brightness1Outlined';
import Button from '@material-ui/core/Button';
//AOS
import AOS from 'aos';
import 'aos/dist/aos.css';
//anime
import anime from 'animejs';

const Intro: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 1200,
        });
        setLoading(true);
    }, []);

    if (loading) {
        //animation-first
        var textWrapper: any = document.querySelector('.introAnimationFirst');
        const regex = /\S/g;
        textWrapper.innerHTML = textWrapper.textContent.replace(
            regex,
            "<span class='letter'>$&</span>"
        );

        anime.timeline({ loop: false }).add({
            targets: '.introAnimationFirst .letter',
            translateX: [40, 0],
            translateZ: 0,
            opacity: [0, 1],
            easing: 'easeOutExpo',
            duration: 1800,
            delay: (el, i) => 500 + 30 * i,
        });
        //animation-second
        // var textWrapperSecond: any = document.querySelector(
        //     '.introAnimationSecond'
        // );
        // const regexSec = /\S/g;
        // textWrapperSecond.innerHTML = textWrapperSecond.textContent.replace(
        //     regexSec,
        //     "<span class='letter'>$&</span>"
        // );

        // anime.timeline({ loop: false }).add({
        //     targets: '.introAnimationSecond .letter',
        //     translateX: [40, 0],
        //     translateZ: 0,
        //     opacity: [0, 1],
        //     easing: 'easeOutExpo',
        //     duration: 1800,
        //     delay: (el, i) => 500 + 30 * i,
        // });
    }

    return (
        <section className='intro'>
            <article className='introCard' data-aos='fade-right'>
                {window.location.pathname === '/' ? (
                    <>
                        <h2 className='introAnimationFirst'>MDance</h2>
                        <p>
                            Powstało z zamiłowania do tańca… Mając na uwadze naturalną
                            potrzebę ruchu, którą posiada każdy z nas oraz wiedzę, jak zwykłe
                            podrygiwanie do muzyki lub wyuczony taniec wpływa korzystnie na
                            rozwój człowieka, bez względu na jego wiek i ogólną sprawność,
                            staramy się stworzyć system nauki dopasowany do jednostki.
                        </p>
                    </>
                ) : (
                    <>
                        <h2 className='introAnimationFirst'>Szkoła tańca</h2>
                        <p>
                            Nasza oferta skierowana jest do każdego. Wyznajemy zasadę, że
                            tańczyć każdy może, dlatego podczas nauczania kierujemy się domeną
                            aby „Uczyć innych tak, jak sami chcielibyśmy być uczeni”.
                        </p>
                    </>
                )}
                {/* <div>
                    <Button color="primary" variant="contained"
                        endIcon={<Brightness1OutlinedIcon />}
                    >
                        Zarezerwuj termin
                    </Button>
                    <Button color="primary" variant="outlined">
                        Jak to działa?
                    </Button>
                </div> */}
            </article>
            <article className='introCard'>
                <img data-aos='fade-down' src={picHero} alt='hero' />
                <img
                    data-aos='fade-up-left'
                    data-aos-duration='1800'
                    data-aos-offset='-200'
                    src={picMobile}
                    alt='mobile'
                />
            </article>
        </section>
    );
};

export default Intro;
