import React from 'react';
import Btn from '../utils/Btn';
import Background from '../../assets/news/brzdace.jpg';
import PhoneIcon from '@material-ui/icons/Phone';

const Brzdace = () => {
  const buttonTitle = 'Zapisy';
  const buttonColor = 'default';
  const btnVariant = 'contained';
  const hoverColor = 'contained';
  const link = 'tel:+48-575-655-355';
  const startIcon: any = <PhoneIcon />;
  return (
    <>
      <section
        className='news'
        style={{
          background: `linear-gradient( rgba(0, 0, 0, 1),rgba(0, 0, 0, 1)),
    url("")`,
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <header>
          <p style={{ color: "#f2f2f2", paddingBottom: '1.5rem' }}>Dzień dobry</p>
          <p>
            <strong style={{ color: "#f2f2f2" }}>Serdecznie zapraszamy wszystkie chętne dzieci do udziału w naszych cyklicznych pokazach tanecznych o nazwie „Tańczące Brzdące”.</strong>{' '}
          </p>{' '}
        </header>
        <article>
          <div className='newsText'>
            <h3 style={{ color: "#f2f2f2" }}>
              {' '}
              W tym roku impreza odbędzie się:
            </h3>
            <ul>
              <li>
                Termin: <strong>3.12.2022 (sobota)</strong>
              </li>
              <li>Przewidywany czas trwania imprezy: 10:00-11:00 (zbiórka o 9:45)</li>
              <li>Miejsce: <strong>Hala Sportowa Szkół Salezjańskich przy ulicy Ku Słońcu 124 w Szczecinie.</strong></li>
            </ul>
            <h1 style={{ marginBottom: '30px' }}> {` `}</h1>
            <p style={{ color: "#f2f2f2" }}>
              Prosimy, aby dzieci ubrane były w stroje galowe - eleganckie.
              Dzieci przygotowywane są do występu od dłuższego czasu.
            </p>
            <p style={{ color: "#f2f2f2" }}>
              Każdy uczestnik otrzyma:
            </p>
            <ul>
              <li>
                Pamiątkowy dyplom
              </li>
              <li>Upominek / zabawkę</li>
              <li>Medal</li>
            </ul>
            <p style={{ color: "#f2f2f2" }}>Zapisy odbywają do dnia 24.11.2022</p>
            <p style={{ color: "#f2f2f2" }}>Szczegółowe informacje:</p>
            <p style={{ color: "#f2f2f2" }}><a style={{ color: "#f2f2f2" }} href="tel:+48-575-655-355">+48 575 655 355</a></p>
            <a href='https://librusgo.pl/course-group-sign-up/9519218155c6' style={{ textDecoration: 'none' }}>
              <Btn
                // startIcon={startIcon}
                title={buttonTitle}
                btnColor={buttonColor}
                btnVariant={btnVariant}
                hoverColor={hoverColor}
              />{' '}
            </a>
            <p style={{ marginTop: '50px', color: '#f2f2f2' }}>
              {' '}
              Nie może Was zabraknąć.
            </p>
            <hr />

            <p style={{ color: "#f2f2f2" }}> Marcel Miczałowski</p>
          </div>
        </article>
      </section>
      <div
        className='news-zaproszenie'
        style={{
          background: `linear-gradient( rgba(0, 0, 0, 0),rgba(0, 0, 0, 0)),
    url(${Background})`,
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      ></div>
    </>
  );
};

export default Brzdace;
