import React from "react";
//components
import About from "../../components/About/About";
import SeoHelmet from "../../components/utils/SeoHelmet";

const AboutPage: React.FC = () => {
  return (
    <>
      <SeoHelmet
        title="MDance Szkoła Tańca"
        description="MDance Szkoła Tańca Szczecin, Taniec dla dzieci, Zajęcia dla Pań Szczecin, Taniec Szczecin"
        imageUrl="https://images.unsplash.com/photo-1535525153412-5a42439a210d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        imageAlt="Szkoła Tańca Szczecin"
      />
      <About />
    </>
  );
};

export default AboutPage;
