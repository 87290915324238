import React from "react";
//material-ui
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textDecoration: "none",
      "& > *": {
        margin: theme.spacing(1),
        letterSpacing: "0.15rem",
        padding: "0.8rem 2rem",
        minHeight: "35px",
        backgroundColor: "#cf3326",
      },
    },
  })
);

interface Props {
  title: string;
  btnColor: "default" | "inherit" | "primary" | "secondary" | undefined;
  btnVariant: "text" | "outlined" | "contained" | undefined;
  hoverColor: string | undefined;
  startIcon?: Element | undefined;
  link?: string;
  type?: any;
}

const Btn: React.FC<Props> = ({
  title,
  btnColor,
  btnVariant,
  hoverColor,
  startIcon,
  link,
  type,
}) => {
  const classes = useStyles();
  return (
    <a className={classes.root} href={link}>
      <Button
        startIcon={startIcon}
        variant={btnVariant}
        color={btnColor}
        className={hoverColor}
        type={type}
      >
        {title}
      </Button>
    </a>
  );
};

export default Btn;
