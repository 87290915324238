import React, { useState } from "react";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";

const override = css`
  display: block;
  margin: 100px;
`;

const Loading: React.FC = () => {
    const [loading, setLoading] = useState(true);
    return (
        <div>
            <BeatLoader
                loading={loading}
                size={20} css={override} />
        </div>
    )
}

export default Loading;
