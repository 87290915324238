import React from "react";
//components
import Btn from "../utils/Btn";
import Background from "../../assets/news/naturalcup.jpg";
//material-ui
import PhoneIcon from "@material-ui/icons/Phone";

const NewsNaturalCup = () => {
  const buttonTitle = "Zapisy";
  const buttonColor = "default";
  const btnVariant = "contained";
  const hoverColor = "contained";
  const link = "tel:+48-575-655-355";
  const startIcon: any = <PhoneIcon />;
  return (
    <section
      className="news"
      style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.99)),
      url(${Background})`,
        backgroundPosition: "50% 30%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <header>
        <p>
          Zapraszamy wszystkich tancerzy oraz osoby kochające taniec do udziału
          w wydarzeniu jakim jest I Ogólnopolski Turniej Tańca Sportowego
          NATURAL CUP.
        </p>
      </header>
      <article>
        <div className="newsText">
          <h3>
            {" "}
            Liczymy, że dzięki Waszej frekwencji i naszej pracy Turniej ten
            zapisze się na stałe w kalendarzu tanecznym, ale również
            szczecińskim kalendarzu wydarzeń kulturalno-sportowych.
          </h3>
          <h3>
            W tym roku widzimy się 23.04.2022 w Sali Gimnastycznej Szkoły
            Podstawowej nr 7 w Szczecinie przy ul. Złotowskiej 86.
          </h3>
          <p style={{ marginTop: "50px" }}> Nie może Was zabraknąć.</p>
          <p> Do zobaczenia</p>
          <hr />

          <p> Zespół MDANCE</p>

          <h6>
            <a
              href="https://drive.google.com/file/d/1LWQHw07fX84WLr9A4al6sjAiEKt0MDGc/view?usp=sharing"
              target="_blank"
              rel="noreferrer"
              style={{ color: "inherit" }}
            >
              <strong style={{ letterSpacing: "3px" }}>
                * REGULAMIN{">>"}{" "}
              </strong>
            </a>
          </h6>
        </div>
      </article>
    </section>
  );
};

export default NewsNaturalCup;
