import React from "react";
//assets
import img12 from "../../assets/offer/taniec-rekreacyjny.jpg";

import img1 from '../../assets/offer/Picture 1.png';
import img2 from '../../assets/offer/Picture 2.png';
import img3 from '../../assets/offer/Picture 3.png';
import img4 from '../../assets/offer/Picture 4.png';
import img5 from '../../assets/offer/Picture 5.png';
import img6 from '../../assets/offer/Picture 6.png';
import img7 from '../../assets/offer/Picture 7.png';
import img8 from '../../assets/offer/Picture 8.png';
import img9 from '../../assets/offer/Picture 9.png';
import img10 from '../../assets/offer/bachata-solo.jpeg';
import img11 from '../../assets/offer/taniec-towarzyski.jpeg';

//components
import Btn from "../utils/Btn";
import CardComp from "../utils/CardComp";
import Intro from "./Intro";
//material-ui
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ContactMailIcon from "@material-ui/icons/ContactMail";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fontSize: "5rem",
      opacity: 0.3,
    },
  }),
);

const Offer = () => {
  //material-ui card icon styling
  const classes = useStyles();
  //buttom var
  const buttonTitle = "Zapisy";
  const buttonColor = "primary";
  const btnVariant = "contained";
  const hoverColor = "contained";
  const startIcon: any = <ContactMailIcon />;

  //cards object
  const cards = [
    {
      title: "Disco Kids",
      desc: "To zajęcia skierowane do tych dzieci, które uwielbiają energię i radość tańca! Nasze zajęcia Disco Kids to doskonała okazja, aby wprowadzić najmłodszych w świat tanecznej zabawy. Dzieci będą miały możliwość nauczenia się podstawowych kroków i figur charakterystycznych dla tańca disco oraz tańca towarzyskiego, jednocześnie rozwijając swoje umiejętności koordynacyjne i wrażliwość muzyczną.",
      img: img1,
      list: null,
    },
    {
      title: "Balet",
      desc: "Jeśli Twoje dziecko marzy o byciu baletnicą lub baletmistrzem, nasze zajęcia z baletu są właśnie dla niego! Przygotowaliśmy program, który umożliwia rozwijanie elegancji, gracji i wytrzymałości fizycznej poprzez naukę klasycznych technik baletowych. Dzięki naszym doświadczonym instruktorom, dzieci będą miały szansę odkrycia piękna i precyzji tego wspaniałego stylu tanecznego.",
      img: img2,
      list: null,
    },
    {
      title: "TikTokowy zawrót głowy",
      desc: "Ten zestaw zajęć jest skierowany do młodszych tancerzy, którzy są zakochani w światowej sławy trendach tanecznych z TikToka. Nauczymy dzieci najnowszych i najbardziej popularnych układów tanecznych, które podbijają internet. To świetna okazja do wyrażenia siebie, kreatywnego tańca i zabawy w grupie.",
      img: img3,
      list: null,
    },
    {
      title: "SPORT Kids",
      desc: "To forma zajęć, która realizuje założenia nauki tańca wraz z usprawnianiem dzieci biorących w nich udział. Podczas zajęć kładziemy nacisk na dobrą zabawę przy jednoczesnym wzmacnianiu u dzieci koordynacji, motoryki małej i dużej oraz wzmacnianiu sprawności akrobatycznej. Nie są to jednak zajęcia z typowej akrobatyki.",
      img: img4,
      list: null,
    },
  ];

  const cards2 = [
    {
      title: "Salsa",
      desc: "Czy marzysz o gorących rytmach latynoskich? Zajęcia z salsy są dla Ciebie! Naucz się podstawowych kroków i figur, które sprawią, że poczujesz się jak na karaibskiej plaży. Nasza szkoła oferuje zarówno zajęcia dla początkujących, jak i dla tancerzy zaawansowanych, dzięki czemu każdy może znaleźć odpowiedni poziom dla siebie. Nie wiesz czym jest salsa? Kliknij w LINK (tutaj dodamy opis, który jest poniżej.",
      img: img5,
      list: null,
    },
    {
      title: "Bachata",
      desc: "Zapraszamy do odkrycia romantycznego i zmysłowego tańca jakim jest Bachata. Ta egzotyczna forma taneczna pochodząca z Dominikany jest pełna emocji i ekspresji. Nasi instruktorzy nauczą Cię podstawowych figur i technik, które pozwolą Ci zanurzyć się w magiczny świat Bachaty. A więcej na temat tego tańca przeczytasz tu LINK (info poniżej)",
      img: img6,
      list: null,
    },
    {
      title: "Disco Fox",
      desc: "Jeśli interesuje Cię taniec towarzyski, ale szukasz czegoś bardziej dynamicznego, i zaraz uniwersalnego to zajęcia z disco fox są idealne dla Ciebie. Ten energetyczny taniec, popularny na parkietach klubowych, pozwoli Ci rozwijać swój styl i technikę, jednocześnie bawiąc się i korzystając z muzyki. Historię Disco Foxa poznasz tutaj LINK",
      img: img7,
      list: null,
    },
    {
      title: "Taniec towarzyski",
      desc: "Nasza szkoła oferuje również zajęcia z tradycyjnego tańca towarzyskiego. Naucz się walca, tanga, quickstepa i innych pięknych tańców, które sprawią, że poczujesz się elegancko i pewnie na parkiecie. Niezależnie od tego, czy jesteś początkującym, czy zaawansowanym tancerzem, nasi instruktorzy pomogą Ci osiągnąć zamierzone cele taneczne.",
      img: img11,
      list: null,
    },
  ];

  const cards3 = [
    {
      title: "FIT Dance",
      desc: "To dynamiczne połączenie zajęć fitness wraz z nauką tańca. Pełne energii zajęcia, do których nie potrzebujesz partnera. Skierowane są specjalnie dla kobiet. Jeśli marzysz o opanowaniu gorących rytmów i stylowych kroków latynoamerykańskich tańców, a jednocześnie odbyć trening kondycyjny, to jesteś we właściwym miejscu!",
      img: img8,
      list: null,
    },
    {
      title: "High Heels",
      desc:
        'Witamy na naszych zajęciach "High Heels"! To wyjątkowe zajęcia, które skupiają się na tańcu na wysokich obcasach. Jeśli chcesz poczuć się pewnie i zmysłowo na parkiecie, ta forma tańca jest dla Ciebie!<br /><br />' +
        'Podczas zajęć "High Heels" nauczysz się sztuki poruszania się elegancko i swobodnie w szpilkach. Nasze instruktorki, które są doświadczonymi tancerkami w tej technice, pomogą Ci opanować różnorodne ruchy, figury i choreografię, które dodadzą Twojemu tańcowi wyjątkowego uroku.<br /><br />' +
        'Nie ważne, czy jesteś początkującą w tańcu na szpilkach czy już posiadasz doświadczenie, nasze zajęcia "High Heels" są dostosowane do różnych poziomów zaawansowania. Będziesz mieć okazję rozwijać swoją technikę, równowagę, siłę i elegancję w tańcu na wysokich obcasach.<br /><br />' +
        'Zapraszamy wszystkie kobiety, niezależnie od wieku i umiejętności, do dołączenia do naszych zajęć "High Heels". Poczujesz się pewna siebie, uwolnisz swoją kobiecość i odkryjesz nowy wymiar tańca na parkiecie.<br /><br />' +
        'Przygotuj swoje ukochane szpilki i dołącz do naszej grupy "High Heels"! Z nami odkryjesz, jak być stylową i pewną siebie tancerką na wysokich obcasach. Nie czekaj dłużej - dołącz do nas i przygotuj się na niezapomniane taneczne  doświadczenie! Zajęcia te będą odbywać się w dwóch odsłonach, zwykły High Heels oraz wersja z krzesłami.<br /><br />',
      img: img9,
      list: null,
    },
    {
      title: "Bachata SOLO",
      desc: "to zajęcia podczas których rozruszamy bioderka w rytm latynoskiej muzyki wprost z Dominikany i poznamy przeróżne kroki zmysłowego tańca jakim jest BACHATA",
      img: img10,
      list: null,
    },
  ];

  return (
    <>
      <Intro />
      <section className="offer">
        <article>
          <div className="offerCards">
            <p className="mb-5">
              W naszej szkole tańca oferujemy różnorodne style taneczne dla osób
              w każdym wieku. Niezależnie od tego, czy jesteś dzieckiem,
              nastolatkiem czy dorosłym, zapraszamy do odkrywania radości z
              tańca i rozwijania swoich umiejętności w przyjaznym środowisku.
              Nasze zajęcia taneczne są doskonałym sposobem na czerpanie radości
              z ruchu, poprawę kondycji fizycznej i odkrywanie piękna tańca jako
              formy aktywności ruchowej.
            </p>
            <div className="singleOffer pt-5">
              <h1>
                <strong>STREFA DZIECKA</strong>
              </h1>
              {cards.map((cardItem, index) => {
                return (
                  <CardComp
                    key={index}
                    title={cardItem.title}
                    desc={cardItem.desc}
                    img={cardItem.img}
                    list={cardItem.list}
                  />
                );
              })}
            </div>
            <div className="singleOffer pt-5">
              <h1 className="mb-5">
                <strong>DLA DOROSŁYCH</strong>
              </h1>
              <h2>Strefa PAR</h2>
              {cards2.map((cardItem, index) => {
                return (
                  <CardComp
                    key={index}
                    title={cardItem.title}
                    desc={cardItem.desc}
                    img={cardItem.img}
                    list={cardItem.list}
                  />
                );
              })}
            </div>
            <div className="singleOffer pt-5">
              <h2>Strefa Kobiet</h2>
              {cards3.map((cardItem, index) => {
                return (
                  <CardComp
                    key={index}
                    title={cardItem.title}
                    desc={cardItem.desc}
                    img={cardItem.img}
                    list={cardItem.list}
                  />
                );
              })}
            </div>

            <p className="py-5">
              Zapraszamy do naszej szkoły, gdzie pasja, rozwój i radość z tańca
              łączą się w jedno! Niezależnie od wieku czy poziomu umiejętności,
              znajdziesz u nas zajęcia dopasowane do Twoich potrzeb i
              zainteresowań. Dołącz do naszej społeczności tanecznej i zacznij
              tworzyć wspaniałe tańczące wspomnienia już dziś!
            </p>
            {window.location.pathname === "/" ? (
              ""
            ) : (
              <a href="https://librusgo.pl/course-group-sign-up/9519218155c6">
                <Btn
                  startIcon={startIcon}
                  title={buttonTitle}
                  btnColor={buttonColor}
                  btnVariant={btnVariant}
                  hoverColor={hoverColor}
                />{" "}
              </a>
            )}
          </div>
        </article>
        {/* <article>
                <div>
                    <p><strong>Korzystamy tylko z wysokiej klasy produktów, których jakość została potwierdzona przez wielu niezależnych ekspertów, ale co najważniejsze, samych użytkowników.</strong></p>
                    <h2>Falowniki:</h2>
                    <div className='offerProducts'>
                        <img src={huawei} alt='falownik huawei' />
                        <img src={fronius} alt='falownik fronius' />
                        <img src={solaredge} alt='falownik solaredge' />
                    </div>
                    <h2>Panele:</h2>
                    <div className='offerProducts'>
                        <img src={canadiansolar} alt='panele słoneczne canadian solar' />
                        <img src={qcells} alt='panele słoneczne q cells' />
                        <img src={longisolar} alt='panele słoneczne longi solar' />
                    </div>
                </div>
            </article> */}
      </section>
    </>
  );
};

export default Offer;
