import React from "react";
//assets
import img1 from "../../assets/slides/slide-3.png";
//components
import Btn from "../utils/Btn";
//material-ui
import PhoneIcon from "@material-ui/icons/Phone";
import Trainers from "./Trainers";

const About = () => {
  const buttonTitle = "Skontaktuj się z nami";
  const buttonColor = "default";
  const btnVariant = "contained";
  const hoverColor = "contained";
  const link = "tel:+48-575-655-355";
  const startIcon: any = <PhoneIcon />;
  return (
    <section className="about">
      <header>
        <h1>
          <strong>MDance</strong>
        </h1>
        <p>
          MDance tworzy grupa osób, dla których taniec jest czymś więcej niż
          tylko możliwością spędzenia wolnego czasu, jest częścią życia.{" "}
        </p>
      </header>
      <article>
        <div className="aboutText">
          {window.location.pathname === "/" && (
            <>
              <h1>
                <strong>Marcel Miczałowski</strong>
              </h1>
              <p>
                {" "}
                Założyciel i główny trener. Autor nowatorskiego programu
                nauczania dzieci zarówno w wieku przedszkolnym i
                wczesnoszkolnym. Historię z tańcem rozpoczął w wieku 7 lat. Od
                14 lat zbierał doświadczenie trenerskie zarówno w Polsce jak i w
                Anglii.
              </p>
              <p>
                Dyplomowany Instruktor Tańca, Dyplomowany Trener Tańca, Magister
                Pedagogiki przedszkolnej i wczesnoszkolne z diagnozą i terapią,
                Sędzia Tańca Sportowego FTS. Pedagog z zamiłowania. Prowadzi
                zajęcia taneczne dla przedszkolaków, dzieci w wieku
                wczesnoszkolnym i szkolnym, grup sportowych oraz dorosłych.{" "}
              </p>
            </>
          )}
          {window.location.pathname.includes("/onas") && <Trainers />}

          <Btn
            startIcon={startIcon}
            title={buttonTitle}
            btnColor={buttonColor}
            btnVariant={btnVariant}
            hoverColor={hoverColor}
            link={link}
          ></Btn>
        </div>
      </article>
    </section>
  );
};

export default About;
