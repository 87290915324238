import React from "react";
//material-ui
import CheckIcon from "@material-ui/icons/Check";
import CardHeader from "@material-ui/core/CardHeader";
interface Props {
  title: string;
  img?: any;
  desc: string;
  list?: string[]|null;
}
const CardComp: React.FC<Props> = ({ title, img, desc, list }) => {
  return (
    <div className="card">
      <header>
        <div className="textContent">
          <h3 className="title">{title}</h3>
          <p className="desc" dangerouslySetInnerHTML={{ __html: desc}} />
          {list && (
            <div className="listContainer">
              {list?.map((listItem, index) => {
                return (
                  <>
                    <CardHeader
                      avatar={<CheckIcon />}
                      title={
                        <p className="desc" key={index}>
                          {listItem}
                        </p>
                      }
                    />
                  </>
                );
              })}
            </div>
          )}
        </div>
        <div className="imageContent">
          {img && <img src={img} alt={title} />}
        </div>
      </header>
    </div>
  );
};
export default CardComp;
