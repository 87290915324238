import React from 'react';
import Btn from '../utils/Btn';
import Background from '../../assets/news/wakacje.png';
import Image from '../../assets/news/wakacje-2023.jpg';
import PhoneIcon from '@material-ui/icons/Phone';

const NewsWakacje = () => {
  const buttonTitle = 'Zapisy';
  const buttonColor = 'default';
  const btnVariant = 'contained';
  const hoverColor = 'contained';
  return (
    <>
      <section
        className='news'
        style={{
          background: `linear-gradient( rgba(236, 236, 236, 0.95),rgba(236, 236, 236, 0.95)),
    url(${Background})`,
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <header>
          <p style={{ color: '#000', paddingBottom: '1.5rem' }}>
            Roztańczone Wakacje 2023{' '}
          </p>
        </header>
        <article>
          <div className='newsText'>
            <p style={{ color: '#000' }}>
              Z radością informujemy, że jak co roku, w okresie wakacyjnym,
              organizujemy wypoczynek letni dla dzieci, który tym razem odbędzie
              się w terminie 02-09.07.2023 w hotelu Gryf w Międzywodziu.
              Przewidywany wiek uczestników to 6-12 lat.
            </p>
            <h3 style={{ color: '#000' }}> Gwarantujemy:</h3>
            <ul style={{ color: '#000' }}>
              <li>transport na trasie Szczecin - Międzywodzie - Szczecin</li>
              <li>całodobową opiekę wykwalifikowanej kadry</li>
              <li>zajęcia taneczne dwa razy dziennie</li>
              <li>wyżywienie: śniadanie, obiad, kolacja</li>
              <li>
                zakwaterowanie w pokojach 2, 3 i 4 osobowych z własnym węzłem
                sanitarnym
              </li>
              <li>całodobową opiekę pielęgniarki</li>
              <li>dostęp do krytego basenu, jacuzzi i kompleksu saun</li>
              <li>wycieczki terenowe</li>
              <li>kąpiele w morzu pod opieką ratownikaa</li>
              <li>cowieczorne imprezy tematyczne</li>
              <li>gry i zabawy z konkursami</li>
              <li>
                każdy uczestnik otrzyma pamiątkową koszulkę, czapeczkę oraz
                dyplom
              </li>
            </ul>
            <h1 style={{ marginBottom: '30px' }}> {` `}</h1>
            <p style={{ color: '#000' }}>Cena to: 1890zł</p>
            <p style={{ color: '#000' }}>
              Zapisy można dokonywać bezpośrednio przez formularz wbierając
              rodzaj zajęć (Roztańczone Wakacje 2023):
            </p>
            <a href='https://librusgo.pl/course-group-sign-up/9519218155c6' style={{ textDecoration: 'none' }} >
              <Btn
                // startIcon={startIcon}
                title={buttonTitle}
                btnColor={buttonColor}
                btnVariant={btnVariant}
                hoverColor={hoverColor}
              />{' '}
            </a>
            <p style={{ color: '#000', marginTop: '30px' }}>
              W razie jakichkolwiek pytań lub wątpliwości pozostaję do Państwa
              dyspozycji.
            </p>
            <p style={{ color: '#000' }}>
              *rezerwacja zostanie utrzymana pod warunkiem wpłacenia zadatku w
              terminie określonym przez organizatora.{' '}
            </p>
          </div>
        </article>
      </section>
      <div
        className='news-zaproszenie'
        style={{
          background: `linear-gradient( rgba(0, 0, 0, 0),rgba(0, 0, 0, 0)),
    url(${Image})`,
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      ></div>
    </>
  );
};

export default NewsWakacje;
