import React from "react";
//router
import { Link } from "react-router-dom";
//assets
import Logo from "../assets/logo.png";
import Facebook from "../assets/facebook.png";
import Instagram from "../assets/instagram.png";
import Youtube from "../assets/youtube.png";
import PFR from "../assets/pfr.jpeg";

const Footer: React.FC = () => {
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <footer>
        <section>
          <div>
            <Link to="/onas" onClick={scrollUp}>
              O nas
            </Link>
            <Link to="/oferta" onClick={scrollUp}>
              Oferta
            </Link>
            <a href="https://librusgo.pl/course-group-sign-up/9519218155c6"  >
              Zapisy
            </a>
            <Link to="/kontakt" onClick={scrollUp}>
              Kontakt
            </Link>
          </div>
        </section>
        <section>
          <img className="logoFooter" src={Logo} alt="logo" />
          <div className="socialMediaFooter">
            <a
              href="https://www.facebook.com/MDanceSzczecin"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Facebook} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/mdance.szczecin/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Instagram} alt="instagram" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCDC3sHma6XVBMnp6kkoQtMA/featured"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Youtube} alt="youtube" />
            </a>
          </div>
          <img className="pfr" src={PFR} alt="pfr" />
        </section>
        <section>
          <div>
            <a href="tel:+48-575-655-355">+48 575 655 355</a>
            <a href="mailto:mdance.szczecin@gmail.com?&subject=MDance&body=Jak możemy pomóc?">
              mdance.szczecin@gmail.com
            </a>
            <a href="https://librusgo.pl/course-group-sign-up/9519218155c6">Zapisy na zajęcia</a>
          </div>
        </section>
      </footer>
      <div className="copyrightsFooter">
        &copy;2021 MDance. All rights reserved.
      </div>
    </>
  );
};

export default Footer;
