import React from "react";
//components
import News from "../../components/News/Brzdace";
import SeoHelmet from "../../components/utils/SeoHelmet";

const NewsPageBrzdace = () => {
  return (
    <>
      <SeoHelmet
        title="MDance Szkoła Tańca"
        description="Taniec dla dzieci,Taniec Szczecin, Tańczące Brzdące"
        imageUrl="https://images.unsplash.com/photo-1535525153412-5a42439a210d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        imageAlt="Szkoła Tańca Szczecin"
      />
      <News />
    </>
  );
};

export default NewsPageBrzdace;
