import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
//react-bootstrap
import { Carousel } from "react-bootstrap";

//AOS
import AOS from "aos";

//slides
import slidesData from "../../data/banner/banner-slides.json";
import "aos/dist/aos.css";

import {
  getCaptionStyling,
  getH1Styling,
  getH2Caption,
  getH2Styling,
} from "./CarouselItem/utils/slideUtils";
// @ts-ignore
import NewsModal from "../Modal/NewsModal";
//compoenents

const HomeJumbotron: React.FC = () => {
  const { bannerContent } = slidesData;
  const [index, setIndex] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    description: "",
    url: "",
    thumbnail: "",
    second_thumbnail: ""
  });
  let history = useHistory();

  useEffect(() => {
    AOS.init({
      offset: 150,
      duration: 500,
    });
  }, []);

  const handleSelect = (
    selectedIndex: React.SetStateAction<number>,
    e: any,
  ) => {
    setIndex(selectedIndex);
  };

  const handleRouting = (id: string) => {
    history.push(`/${id}`);
  };

  const handleSlideClick = (
    url: string,
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    e.stopPropagation(); // Stop the event propagation
    if (url === "#") {
      return;
    }

    if (url.startsWith("/")) {
      return handleRouting(url.slice(1));
    }

    window.location.href = url;
  };

  const setModalSlide = (title, description, url, thumbnail, second_thumbnail) => {
    setModalContent({
      title,
      description,
      url,
      thumbnail,
      second_thumbnail
    });
    setModal(true);
  };

  return (
    <React.Fragment>
      <Carousel
        indicators={false}
        activeIndex={index}
        onSelect={handleSelect}
        nextLabel=""
        prevLabel=""
        fade
      >
        {bannerContent.map((slide, i) => {
          const { title, subtitle, url, thumbnail, style, description, second_thumbnail } = slide;
          const captionStyling = getCaptionStyling(style, url);
          const h1Styling = getH1Styling(style);
          const h2Styling = getH2Styling(style);
          const h2Caption = getH2Caption(subtitle, url);

          const interval = url === "#" ? 3000 : 5000;
          const hasDescription = description?.trim() !== "";

          return (
            <Carousel.Item
              interval={interval}
              onClick={(e) => handleSlideClick(url, e)}
            >
              <img
                className="d-block w-100"
                src={thumbnail}
                alt={`Slide number ${index}`}
                style={{ objectFit: 'contain', maxHeight: '80vh'}}
              />
              <Carousel.Caption style={{ ...captionStyling }}>
                <h1 data-aos="fade-right" style={{ ...h1Styling }}>
                  {title}
                </h1>
                <h2 style={{ ...h2Styling }}>{h2Caption}</h2>
                {hasDescription ? (
                  <div
                    className="py-5 d-flex justify-content-center"
                    style={{ position: "relative" }}
                  >
                    <h3
                      className="banner-description-modal"
                      onClick={(e) => {
                        e.stopPropagation();
                        setModalSlide(title, description, url, thumbnail, second_thumbnail);
                      }}
                    >
                      SZCZEGÓŁY
                    </h3>
                  </div>
                ) : null}
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
      <NewsModal
        modalOpen={modal}
        setModalOpen={setModal}
        modalContent={modalContent}
      />
    </React.Fragment>
  );
};

export default HomeJumbotron;
