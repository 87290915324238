import React, { useState } from 'react';
//components
import Btn from '../utils/Btn';
import AlertMessage from '../utils/AlertMessage';
//material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      padding: '4rem',
      backgroundColor: '#2b2b2b',
    },
    form: {
      display: 'flex',
      justifyConten: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      '& .MuiFormLabel-root': {
        color: '#000',
        letterSpacing: '0.2rem',
      },
      '& .MuiInputLabel-shrink': {
        margin: '-0.5rem',
        color: '#f5f5f5',
      },
    },
    textContainer: {
      display: 'flex',
      justifyConten: 'center',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    text: {
      margin: '1rem',
      backgroundColor: "#f5f5f5",
      borderRadius: '3px',
      border: '0',
    },

    notch: {
      border: '0',
    },

    checkbox: {
      color: 'white',
    },
    controlLabel: {
      color: 'white',
      marginBottom: '3rem',
    },
    customTooltip: {
      maxWidth: 500,
      fontSize: '1rem',
      letterSpacing: '0.1rem',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 300,
      },
    },
  })
);

const ContactForm: React.FC = () => {
  const classes = useStyles();
  const buttonTitle = 'Wyślij';
  const buttonColor = 'primary';
  const btnVariant = 'contained';
  const hoverColor = 'contained';

  //useState hooks
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [style, setStyle] = useState('');
  const [message, setMessage] = useState('');
  const [booking, setBooking] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    message: '',
  });

  //checked box
  const handleCheckBox = () => {
    setChecked(!checked)
  }
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setBooking({ ...booking, [e.target.name]: e.target.value });
  };
  //reset form
  const resetForm = () => {
    setBooking({
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      message: '',
    });
  };

  //submit button
  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    // post booking details
    fetch('https://main--mdance-service.netlify.app/.netlify/functions/api/send', {
      method: 'POST',
      body: JSON.stringify(booking),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.statusCode === 200) {
          console.log("status", response.status)
          setStyle('success');
          setMessage('Wiadomość została pomyślnie wysłana');
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3500);
          resetForm();
        } else if (response.status === 'fail') {
          console.log("status", response.status)
          setStyle('error');
          setMessage('Proszę uzupełnić wymagane pola!');
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3500);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const text = "Wyrażam zgodę na przetwarzanie moich danych osobowych, podanych w powyższym formularzu, wyłącznie w celu udzielenia odpowiedzi na wysłaną przeze mnie wiadomość.Podane w formularzu dane osobowe mogą być przetwarzane przez administratora zgodnie z obowiązującymi w tym zakresie przepisami RODO oraz Polityką Prywatności. Podanie danych jest dobrowolne. Administrator umożliwia m.in. wgląd do danych osobowych i zapewnia prawo ich poprawiania, jak i usunięcia."

  return (
    <section className='contactForm'>
      <Card elevation={3} className={classes.root}>
        <h1>Skontaktuj się z nami</h1>
        {window.location.pathname === "/kontakt" ? <><h5>+48 575 655 355<br />mdance.szczecin@gmail.com</h5></> : ""}
        <form className={classes.form} onSubmit={handleSubmit} noValidate={false} autoComplete='off'>
          <div className={classes.textContainer}>
            <TextField InputProps={{ classes: { notchedOutline: classes.notch } }} className={classes.text} label='Imię' variant='outlined' fullWidth name='firstname' onChange={(e) => handleChange(e)} value={booking.firstname} inputProps={{
              minLength: 2,
            }} required />
            <TextField InputProps={{ classes: { notchedOutline: classes.notch } }} className={classes.text} label='Nazwisko' variant='outlined' fullWidth name='lastname' onChange={(e) => handleChange(e)} value={booking.lastname} inputProps={{
              minLength: 2,
            }} required />
          </div>
          <div className={classes.textContainer}>
            <TextField InputProps={{ classes: { notchedOutline: classes.notch } }} className={classes.text} label='Nr tel' variant='outlined' fullWidth
              type='number'
              name='phone' value={booking.phone}
              onChange={(e) => handleChange(e)} inputProps={{
                minLength: 8,
              }} required />
            <TextField InputProps={{ classes: { notchedOutline: classes.notch } }} className={classes.text} label='Email' variant='outlined' fullWidth
              type='email'
              name='email' value={booking.email}
              onChange={(e) => handleChange(e)} inputProps={{
                minLength: 3,
              }} required />
          </div>
          <div className={classes.textContainer}>
            <TextField InputProps={{ classes: { notchedOutline: classes.notch } }} className={classes.text} label='Wiadomość' variant='outlined' multiline rows={3} fullWidth name='message' value={booking.message}
              onChange={(e) => handleChange(e)} helperText='Napisz jak możemy pomóc' required />
          </div>
          <Tooltip TransitionComponent={Fade} title={text} classes={{ tooltip: classes.customTooltip }} arrow>
            <FormControlLabel
              control={<Checkbox
                checked={checked}
                required
                onChange={handleCheckBox}
                color="secondary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                className={classes.checkbox}
              />}
              label={`${text.slice(0, 180)}...`}
              className={classes.controlLabel}
            />
          </Tooltip>
          <Btn title={buttonTitle} btnColor={buttonColor} btnVariant={btnVariant} hoverColor={hoverColor} type='submit' />
          <AlertMessage alertStyle={style} message={message} open={open} />
        </form>
      </Card>
    </section>
  );
};

export default ContactForm;
