import React from "react";

const Trainers = () => {
  return (
    <>
      <h1>
        <strong>Witamy w Szkole Tańca MDANCE!</strong>
      </h1>
      {/*<p>Witamy w naszej szkole tańca!</p>*/}
      <p className="pt-3">
        Jesteśmy szkołą tańca, która powstała z głębokiego zamiłowania do tej
        sztuki. Od 2018 roku oferujemy profesjonalne zajęcia taneczne dla
        dzieci, młodzieży i dorosłych. Naszym założycielem i głównym trenerem
        jest Marcel Miczałowski, który posiada bogate kwalifikacje, w tym tytuł
        Magistra Wychowania Przedszkolnego i Wczesnoszkolnego z Diagnozą i
        Terapią. Jest także dyplomowanym trenerem 2. kategorii oraz Sędzią
        Polskiego Związku Sportu Tanecznego.
      </p>
      <p>
        W naszej szkole kładziemy nacisk na taniec towarzyski, który stanowi
        naszą flagową formę. Jednak nie ograniczamy się tylko do niego.
        Oferujemy również zajęcia z Bachaty, salsy, discofoxu, Tańca
        współczesnego, nowoczesnego, baletu i stale poszerzamy naszą ofertę, aby
        sprostać oczekiwaniom naszych klientów.
      </p>
      <p>
        Jesteśmy dumni z naszego autorskiego programu edukacji tanecznej,
        zwanego "Nowym Wymiarem Tańca", który realizujemy w przedszkolach i
        szkołach podstawowych. Program ten skupia się na prawidłowym i
        zrównoważonym rozwoju dzieci, zwracając szczególną uwagę na ich potrzeby
        i umiejętności taneczne.
      </p>
      <p>
        Jesteśmy również zaangażowani w działalność Klubu Tańca Sportowego o
        nazwie MDANCE Szczecin i jesteśmy dumni z naszego członkostwa w Polskim
        Związku Sportu Tanecznego, realizując wszystkie założenia rywalizacji
        sportowej.
      </p>
      <p>
        Co roku od 5 lat organizujemy letnie obozy taneczne dla naszych uczniów,
        które noszą nazwę "Roztańczone Wakacje". To wyjątkowe wydarzenie cieszy
        się ogromnym zainteresowaniem i pozwala naszym podopiecznym rozwijać
        swoje umiejętności taneczne i nawiązywać nowe przyjaźnie.
      </p>
      <p>
        Nasi utalentowani tancerze realizują pokazy taneczne podczas różnych
        wydarzeń kulturalnych. Zaufali nam między innymi REALBUD, Vienna Life,
        Siedziba NATO w Szczecinie, Wielka Orkiestra Świątecznej Pomocy oraz
        wiele prywatnych osób, dla których dostarczamy niezapomniane wrażenia
        taneczne podczas ich indywidualnych przedsięwzięć.
      </p>
      <p>
        W naszej szkole rozumiemy, jak zajęcia muszą być dostosowane w
        zależności od wieku. Dlatego utworzyliśmy specjalną strefę dla dzieci,
        gdzie oprócz codziennych, zróżnicowanych zajęć z tańca skupiamy się nad
        zrównoważonym i prawidłowym ich rozwojem już od najmłodszych ich lat.
        Mając świadomość naturalnej potrzeby ruchu, utworzyliśmy specjalne
        pakiety urodzinowe dla dzieci, dając możliwość aktywnego świętowania
        swoich urodzin nie tylko w rytmie tańca. To doskonała okazja, aby dzieci
        mogły spędzić wyjątkowy czas na parkiecie, bawiąc się i ucząc się pod
        okiem naszych doświadczonych instruktorów. Oferta realizowana jest
        również w domu klienta
      </p>
      <p className="mb-4">
        Zapraszamy do naszej szkoły tańca, gdzie pasja, profesjonalizm i radość
        z tańca spotykają się w jednym miejscu!
      </p>
      <h1>Nasza Kadra:</h1>
      <div className="single pt-5">
        <p>
          <strong>• Marcel Miczałowski</strong>
        </p>
        <p>
          Założyciel i główny trener. Autor nowatorskiego programu nauczania
          dzieci zarówno w wieku przedszkolnym i wczesnoszkolnym. Historię z
          tańcem rozpoczął w wieku 7 lat. Od 14 lat zbierał doświadczenie
          trenerskie zarówno w Polsce jak i w Anglii.
        </p>
        <p>
          Specjalista w zakresie tańców standardowych. Dyplomowany Instruktor
          Tańca, Dyplomowany Trener Tańca PZST, Magister Pedagogiki
          przedszkolnej i wczesnoszkolnej z diagnozą i terapią, Sędzia Tańca
          Sportowego FTS i PZST. Pedagog z zamiłowania. Prowadzi zajęcia
          taneczne dla przedszkolaków, dzieci w wieku wczesnoszkolnym i
          szkolnym, grup sportowych oraz dorosłych.
        </p>
        <div className="single pt-5">
          <p>
            <strong>• Tomasz Żukowski</strong>
          </p>
          <p>
            To nasz stały konsultant w tańcach latynoamerykańskich. Wielokrotny
            Finalista Mistrzostw Polski w stylu latyno-amerykańskim, wielokrotny
            finalista Mistrzostw Polski w 10 Tańcach. <br />
            Aktualnie Trener 2 kategorii Polskiego Związku Sportu Tanecznego,
            Sędzia 1 kategorii i Sędzia Główny, Posiadający kwalifikacje
            sędziowskie Międzynarodowej Organizacji Tańca WDSF.
          </p>
        </div>
      </div>
      <div className="single pt-5">
        <p>
          <strong>• Gracjan Małowiejski</strong>
        </p>
        <p>
          Mgr Wychowania Fizycznego ze specjalizacją kształtowania wad postawy
          od dziecka miał zamiłowanie do muzyki i tańca inspirując się muzyką i
          choreografiami Michaela Jacksona. Prawdziwa przygoda z tańcem zaczęła
          się w 5 klasie podstawówki w wieku 11 lat kiedy to w telewizji
          wyemitowano 1 edycje tańca z gwiazdami i wraz z rodzicami postanowił
          że zapisze się na taniec towarzyski. Jego kariera trwała do momentu
          rozpoczęcia studiów, w tym czasie wielokrotnie stawała na najwyższych
          miejscu na podium podczas wielu turniejów GRAND PRIX oraz rangi
          Mistrzowskiej. Od roku 2019 związany ze Szkołą tańca MDANCE prowadzi
          zajęcia zarówno dla grup przedszkolnych, szkolnych i sportowych. Praca
          z parami sportowymi na nowo tchnęła w niego ducha rywalizacji i od
          roku 2022 podjął decyzję o powrocie na taneczne parkiety. Obecnie
          reprezentuje Klub MDANCE w stylu Latynoamerykańskim.
        </p>
      </div>
      <div className="single pt-5">
        <p>
          <strong>• Olga Kuźmina-Pietkiewicz</strong>
        </p>
        <p>
          Olga Kużmina – Pietkiewicz ukończyła Państwową Szkołę Baletową w
          Kijowie w 2004 roku. W 2008 roku ukończyła Międzynarodowy Uniwersytet
          Słowiański i otrzymała kwalifikację korepetytora baletu klasycznego.
          Pracowała w Kijowskim Państwowym Teatrze Muzycznym Opery i Baletu w
          latach 2004-2005. Brała udział w spektaklach:
          <ul>
            <li>Dziadek do orzechów</li>
            <li>Jezioro Łabędzie</li>
            <li>Dziecko i Czary</li>
            <li>Giselle</li>
            <li>Romeo i Julia</li>
          </ul>
        </p>
        <p>
          W 2006 roku była przyjęta do Opery na Zamku. W 2007 roku brała udział
          w EYDP w Sztokholmie w zespole baletowym ” Les Ballets Persans”. W
          2008 roku pracowała w Operze Wrocławskiej. Brała udział w
          Międzynarodowych Konkursach Baletowych tj.: Warna w Bułgarii, Premio
          di Roma w Rzymie, w Kijowie. Jest finalistką konkursu w Rzymie (2006).
        </p>
        <p>
          W Operze na Zamku w Szczecinie tańczyła w przedstawieniach baletowych:
          <ul>
            <li>
              <a href="https://www.opera.szczecin.pl/component/repertuar/spektakl/275">
                Rock’n ballet
              </a>
            </li>
            <li>
              <a href="https://www.opera.szczecin.pl/component/repertuar/spektakl/249#!GAM_70932">
                Dzieje Grzechu
              </a>
            </li>
            <li>
              <a href="https://www.opera.szczecin.pl/component/repertuar/spektakl/264-alicja-w-krainie-czarow#!GAM_1903">
                Alicja w krainie czarów
              </a>
            </li>
            <li>
              <a href="https://www.opera.szczecin.pl/images/igallery/resized/101-200/IMG_6894_2-135-900-700-80.jpg">
                Napój miłosny
              </a>
            </li>
            <li>Odcienie namiętności</li>
            <li>
              <a href="https://www.youtube.com/watch?v=F-0KvsfL7HM&ab_channel=OperaNaZamkuwSzczecinie">
                Ogniwa
              </a>
            </li>
            <li>
              <a href="#https://www.opera.szczecin.pl/component/repertuar/spektakl/37-kopciuszek#!scena_baletowa">
                Kopciuszek
              </a>
            </li>
            <li>Magia czarów</li>
            <li>
              <a href="#https://www.opera.szczecin.pl/component/repertuar/spektakl/13-corka-zle-strzezona#!K._Naumets__balet">
                Córka źle strzeżona
              </a>
            </li>
            <li>
              <a href="http://radioszczecin.pl/172,3538,dziadek-do-orzechow-w-operze-na-zamku-relacja&sp=1">
                Dziadek do orzechów
              </a>
            </li>
            <li>Ngoma – tańczący słoń</li>
          </ul>
        </p>
        <p>
          oraz spektaklach operowych i operetkowych:
          <ul>
            <li>
              <a href="https://opera.szczecin.pl/component/repertuar/spektakl/287-bal-maskowy#!bal_maskowy_fot_D_Gorajski_9">
                Bal Maskowy
              </a>
            </li>
            <li>
              <a href="https://cojestgrane.pl/polska/zachodniopomorskie/szczecin/wydarzenie/2qh2/hrabina-marica/bylo">
                Hrabina Marica
              </a>
            </li>
            <li>Księżniczka czardasza</li>
            <li>Halka</li>
            <li>Paria</li>
            <li>Cygańska miłość Cnotliwej Zuzanny</li>
            <li>Wiedeńska krew</li>
            <li>Noc w Paryżu</li>
            <li>Zemsta nietoperza</li>
          </ul>
        </p>
        <p>
          Prowadzi zajęcia{" "}
          <a href="http://kuzmina.pl/trening-personalny">indywidualne</a> i{" "}
          <a href="http://kuzmina.pl/zajecia-grupowe">grupowe</a>.
        </p>
      </div>
      <div className="single pt-5">
        <p>
          <strong>• Agata Korzemiacka</strong>
        </p>
        <p>
          Trener, specjalista ds. żywienia, szkoleniowiec, elektroradiolog oraz
          autorka wielu programów dydaktycznych (m.in. Personal Trainer IFAA &
          ATHLETE PERFORMANCE, Modelowanie Pośladków, czy Trening Funkcjonalny),
          akupunkturzystka, studentka fizjoterapii oraz właścicielka firmy
          szkoleniowej ATHLETE PERFORMANCE, objętej patronatem IFAA Polska oraz
          REPs. Była tancerka baletu klasycznego. Swoje doświadczenie i pasję
          kieruje w stronę traumatologii układu ruchu, ukrytych wad postawy oraz
          dietetyki sportowej. <br />
          Propagatorka koncepcji treningu funkcjonalnego oraz zdrowego stylu
          życia. Blackroll Master Trainer zgłębiający koncepcję pracy na powięzi
          i techniki SMR (self myofascial release).
          <br />
          Współwłaścicielka Firmy Aveppi. Trenerka przygotowania motorycznego
          ukierunkowanego pod sporty walki.
        </p>
      </div>
    </>
  );
};

export default Trainers;
