import React from 'react';
//messanger chat
import MessengerCustomerChat from 'react-messenger-customer-chat';

const Messenger = () => {
    return (
        <div className='messenger'>
            <MessengerCustomerChat
                pageId='1420937461569618'
                appId='138258335110206'
            />
        </div>
    )
}

export default Messenger;
