import React from "react";
import { Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import Btn from "../utils/Btn";

const NewsModal = (props) => {
  const { modalOpen, setModalOpen, modalContent } = props;
  const { title, description, url, thumbnail, second_thumbnail} = modalContent;

  let prepDescription = description;

  if (second_thumbnail) {
    prepDescription = description?.replaceAll('[photo]', `<img src="${second_thumbnail}" style="max-width: 100%;margin-top: 10px; margin-bottom: 10px;" alt="" />`) || '';
  }

  return (
    <Modal
      show={modalOpen}
      onHide={() => setModalOpen(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="news-modal"
    >
      <Modal.Header className="p-5 pb-2" style={{ position: "relative" }}>
        <h2 className="news-modal__title">{title}</h2>
        <Button
          className="news-modal__close"
          onClick={() => setModalOpen(false)}
        >
          X󠁘󠁘󠁘󠁘
        </Button>
      </Modal.Header>
      󠁘󠁘󠁘
      <Modal.Body className="p-5" style={{ overflowY: 'auto', maxHeight: '400px' }}>
        <div>
          <img src={thumbnail} style={{ width: '100%', maxHeight: '500px', objectFit: 'contain' }} alt="news-modal-img" />
        </div>
        <p
          className="news-modal__description"
          style={{ paddingTop: "15px" }}
          dangerouslySetInnerHTML={{ __html: prepDescription }}
        />
        <div className="news-modal__button-wrapper">
          <Btn
            title="Zapisy"
            btnColor="primary"
            btnVariant="contained"
            hoverColor="contained"
            link={"https://librusgo.pl/course-group-sign-up/9519218155c6"}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => setModalOpen(false)}
          className="news-modal__close-button"
        >
          Zamknij
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewsModal;
