import React, { useState } from "react";
//router
import { Link, NavLink } from "react-router-dom";
//assets
import Logo from "../assets/logo.png";
//components
import Btn from "./utils/Btn";

const NavBar: React.FC = () => {
  const buttonTitle = "Zapisy";
  const buttonColor = "primary";
  const btnVariant = "contained";
  const hoverColor = "contained";

  const [modal, setModal] = useState(false);

  const openModal = () => {
    setModal(!modal);
  };

  const closeModal = () => {
    setModal(false);
    scrollUp();
  };

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const modalEl = (
    <div onClick={openModal} className="navicon">
      <div className={modal ? "navicon-btn active" : "navicon-btn not-active"}>
        <span className=" navicon-span navicon-span-first"></span>
        <span className="navicon-span navicon-span-second"></span>
        <span className="navicon-span navicon-span-third"></span>
      </div>
    </div>
  );

  return (
    <>
      {!modal ? (
        <header className="navbar">
          <NavLink to="/">
            <img src={Logo} alt="logo" onClick={scrollUp} />
          </NavLink>
          <nav>
            <Link to="/onas" onClick={scrollUp}>
              O nas
            </Link>
            <Link to="/oferta" onClick={scrollUp}>
              Oferta
            </Link>
            {/* <Link to='/grafik'onClick={scrollUp}>Grafik</Link> */}
            <Link to="/galeria" onClick={scrollUp}>
              Galeria
            </Link>
            <Link to="/kontakt" onClick={scrollUp}>
              Kontakt
            </Link>
          </nav>
          <div className="modalBtn">
            <a href="https://librusgo.pl/course-group-sign-up/9519218155c6" onClick={() => scrollUp()}>
              {" "}
              <Btn
                title={buttonTitle}
                btnColor={buttonColor}
                btnVariant={btnVariant}
                hoverColor={hoverColor}
              />
            </a>
          </div>
          {modalEl}
        </header>
      ) : (
        <header className="navbarModal">
          <Link
            to="/"
            onClick={() => {
              scrollUp();
              closeModal();
            }}
          >
            <img src={Logo} alt="logo" />
          </Link>
          {modalEl}
          <nav>
            <Link
              to="/onas"
              onClick={() => {
                scrollUp();
                closeModal();
              }}
            >
              O nas
            </Link>
            <Link
              to="/oferta"
              onClick={() => {
                scrollUp();
                closeModal();
              }}
            >
              Oferta
            </Link>
            {/* <Link to='/grafik'onClick={()=>{scrollUp();closeModal();}}>Grafik</Link> */}
            <Link
              to="/galeria"
              onClick={() => {
                scrollUp();
                closeModal();
              }}
            >
              Galeria
            </Link>
            <Link
              to="/kontakt"
              onClick={() => {
                scrollUp();
                closeModal();
              }}
            >
              Kontakt
            </Link>
          </nav>
          <Link
            to="https://librusgo.pl/course-group-sign-up/9519218155c6"
            onClick={() => {
              scrollUp();
              closeModal();
            }}
          >
            {" "}
            <Btn
              title={buttonTitle}
              btnColor={buttonColor}
              btnVariant={btnVariant}
              hoverColor={hoverColor}
            />
          </Link>
        </header>
      )}
    </>
  );
};

export default NavBar;
