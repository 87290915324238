import React from 'react';
import Btn from '../utils/Btn';
import Background from '../../assets/news/mistrzostwa.jpg';
import PhoneIcon from '@material-ui/icons/Phone';

const Mistrzostwa = () => {
  const buttonTitle = 'Zapisy';
  const buttonColor = 'default';
  const btnVariant = 'contained';
  const hoverColor = 'contained';
  const link = 'tel:+48-575-655-355';
  const startIcon: any = <PhoneIcon />;
  return (
    <>
      <section
        className='news'
        style={{
          background: `linear-gradient( rgba(0, 0, 0, 1),rgba(0, 0, 0, 1)),
    url("")`,
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <header>
          <p style={{ color: "#f2f2f2", paddingBottom: '1.5rem' }}>Mistrzostwa i Puchar Zachodniopomorskiego Związku Tańca Sportowego</p>
        </header>
        <article>
          <div className='newsText'>
            <p style={{ color: "#f2f2f2" }}>
              Serdecznie zapraszamy na Mistrzostwa i Puchar Zachodniopomorskiego Związku Tańca Sportowego. Impreza odbędzie się 3 i 4.12.2022 w Hali Sportowej Szkół Salezjańskich przy ul. Ku Słońcu 124.
              W turnieju wezmą udział wszystkie pary z województwa Zachodniopomorskiego, zrzeszone w Federacji Tańca Sportowego oraz w Polskim Związku Sportu Tanecznego.
            </p>
            <h3 style={{ color: "#f2f2f2" }}>
              {' '}
              Organizatorami wydarzenia są:
            </h3>
            <ul>
              <li>
                Stowarzyszenie Miłośników Tańca MDANCE
              </li>
              <li>Gmina Miasto Szczecin</li>
              <li>Zachodniopomorski Związek Tańca Sportowego</li>
              <li>Federacja Tańca Sportowego</li>
              <li>Polski Związek Sportu Tanecznego</li>
            </ul>
            <h1 style={{ marginBottom: '30px' }}> {` `}</h1>
            <p style={{ color: "#f2f2f2" }}>
              Wszystkie bieżące informacje znajdziecie Państwo na naszym wydarzeniu na FB:
            </p>
            <a href='https://fb.me/e/20Wf5Jtbe' target="_blank"
              rel="noreferrer" style={{ fontSize: '1.5rem' }}>
              FTS FACEBOOK
            </a>
            <p style={{ color: "#f2f2f2" }}>
              Oraz na stronie wydarzenia
            </p>
            <a href='https://baza.fts-taniec.pl/x/reg/50001289' target="_blank"
              rel="noreferrer" style={{ fontSize: '1.5rem' }}>
              FTS WYDARZENIA
            </a>
            <p style={{ color: "#f2f2f2", paddingTop: '2.5rem' }}>Więcej szczegółów:</p>
            <p style={{ color: "#f2f2f2" }}><a style={{ color: "#f2f2f2" }} href="tel:+48-575-655-355">+48 575 655 355</a></p>
            <p style={{ color: "#f2f2f2" }}><a style={{ color: "#f2f2f2" }} href="mailto:mdance.szczecin@gmail.com?&subject=MDance&body=Jak możemy pomóc?">mdance.szczecin@gmail.com
            </a></p>

          </div>
        </article>
      </section>
      <div
        className='news-zaproszenie'
        style={{
          background: `linear-gradient( rgba(0, 0, 0, 0),rgba(0, 0, 0, 0)),
    url(${Background})`,
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      ></div>
    </>
  );
};

export default Mistrzostwa;
