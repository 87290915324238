import React from 'react';
//assets
import prevIcon from "../../assets/prev-icon-white.png";
import nextIcon from "../../assets/next-icon-white.png";
import xIcon from "../../assets/x-icon.png";


interface Props {
    id: number,
    category?: string,
    url: string
}

const ModalGallery: React.FC<Props> = ({ id, url }) => {

    return (
        <section className="modalContainer">
            <img className="modalImage" src={url} alt={`modal-${id}`} />
            <img className="prevIcon" src={prevIcon} alt="prev-icon" />
            <img className="nextIcon" src={nextIcon} alt="next-icon" />
            <img className="xIcon" src={xIcon} alt="x-icon" />
        </section>
    )
}

export default ModalGallery;
