export const getCaptionStyling = (style: string, url: string): object => {
  if (url === "#") {
    return {};
  }
  return {
    maxWidth: "60%",
    backgroundColor: "rgba(0, 0, 0, 0.65)",
    cursor: "pointer",
  };
};

export const getH1Styling = (style: string): object => {
  if (style === "red") {
    return {
      color: "#cf3326",
      marginTop: "2rem",
      textShadow: "2px 2px #000",
    };
  }
  return {};
};

export const getH2Styling = (style: string): object => {
  if (style === "red") {
    return {
      color: "#f5f5f5",
      textShadow: "2px 2px #000",
      fontSize: "1.4em",
    };
  }
  return {};
};

export const getH2Caption = (subtitle: string, url: string): string => {
  if (url === "#") {
    return subtitle;
  }
  return `${subtitle} >>`;
};
