import React from "react";
//components
import Gallery from "../../components/Gallery/Gallery";
import SeoHelmet from "../../components/utils/SeoHelmet";

const GalleryPage: React.FC = () => {
  return (
    <>
      <SeoHelmet
        title="Taniec dla dzieci"
        description="Taniec dla dzieci, Zajęcia dla Pań Szczecin"
        imageUrl="https://images.unsplash.com/photo-1535525153412-5a42439a210d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        imageAlt="Zajęcia dla Pań Szczecin"
      />
      <Gallery />
    </>
  );
};

export default GalleryPage;
