import React from 'react';
import Btn from '../utils/Btn';
import Background from '../../assets/news/zaproszenie.jpg';
import PhoneIcon from '@material-ui/icons/Phone';

const Zaproszenie = () => {
  const buttonTitle = 'Zapisy';
  const buttonColor = 'default';
  const btnVariant = 'contained';
  const hoverColor = 'contained';
  const link = 'tel:+48-575-655-355';
  const startIcon: any = <PhoneIcon />;
  return (
    <>
      <section
        className='news'
        style={{
          background: `linear-gradient( rgba(0, 0, 0, 1),rgba(0, 0, 0, 1)),
    url("")`,
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <header>
          <p style={{ color: "#f2f2f2" }}>SPEŁNIJ Z NAMI SWOJE TANECZNE MARZENIA!!</p>
          <p>
            <strong style={{ color: "#f2f2f2" }}>Już dziś DOŁĄCZ DO NASZEJ ROZTAŃCZONEJ GRUPY!</strong>{' '}
          </p>{' '}
        </header>
        <article>
          <div className='newsText'>
            <h3 style={{ color: "#f2f2f2" }}>
              {' '}
              Jeżeli posiadasz takie zaproszenie jak poniżej artykułu, to jesteś
              ogromnym szczęściarzem. Dzięki niemu możesz uczestniczyć w
              wybranych przez siebie zajęciach*, do końca września ZA DARMO! Im
              wcześniej z niego skorzystasz, tym lepiej dla Ciebie.
            </h3>
            <h3 style={{ color: "#f2f2f2" }}>
              Jeżeli jeszcze go nie masz, to nic nie tracisz. Po prostu wejdź na
              naszą stronę FB lub Instagram i Znajdź Post z tym zaproszeniem.
              Polub go, Udostępnij i Napisz w komentarzu imię dziecka, które
              miało by z niego skorzystać. POCZEKAJ NA NASZĄ ODPOWIEDŹ I ZAPISZ
              SIĘ NA ZAJĘCIA korzystając z linku poniżej.
            </h3>
            <h1 style={{ marginBottom: '30px' }}> {` `}</h1>
            <p style={{ color: "#f2f2f2" }}>
              !! Proszę pamiętać, że my musimy widzieć udostępnienia. Jeżeli
              masz częściowo zablokowane konto, to będzie to niemożliwe!!
            </p>

            <p style={{ color: "#f2f2f2" }}>ZAPISUJĄC SIĘ na zajęcia napisz w komentarzu zaproszenie.</p>
            <p style={{ color: "#f2f2f2" }}>Czekamy właśnie na Ciebie ;-)</p>
            <p style={{ marginTop: '50px', color: '#f2f2f2' }}>
              {' '}
              W razie jakichkolwiek pytań lub wątpliwości zachęcamy do kontaktu.
            </p>
            <p style={{ color: "#f2f2f2" }}> Do zobaczenia</p>
            <hr />

            <p style={{ color: "#f2f2f2" }}> Zespół MDANCE</p>
            <div>
              <a href='https://librusgo.pl/course-group-sign-up/9519218155c6' style={{ textDecoration: 'none' }}>
                <Btn
                  // startIcon={startIcon}
                  title={buttonTitle}
                  btnColor={buttonColor}
                  btnVariant={btnVariant}
                  hoverColor={hoverColor}
                />{' '}
              </a>
            </div>

            <small style={{ color: "#f2f2f2" }}>
              * Promocja dotyczy jednego rodzaju zajęć dla dzieci z zakresu:
              Disco KIDS, Taniec towarzyski, Taniec Rekreacja, Breaking
              <br />
              ** Organizator nie zwraca ekwiwalentu za nie wykorzystanie
              zaproszenia
            </small>
          </div>
        </article>
      </section>
      <div
        className='news-zaproszenie'
        style={{
          background: `linear-gradient( rgba(0, 0, 0, 0),rgba(0, 0, 0, 0)),
    url(${Background})`,
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      ></div>
    </>
  );
};

export default Zaproszenie;
