import React from "react";
//components
import ContactForm from "../../components/Form/ContactForm";
import SeoHelmet from "../../components/utils/SeoHelmet";

const Contact: React.FC = () => {
  return (
    <>
      <SeoHelmet
        title="Obozy taneczne"
        description="Taniec Szczecin, kolonie taneczne, obozy taneczne"
        imageUrl="https://images.unsplash.com/photo-1535525153412-5a42439a210d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        imageAlt="Taniec dla dzieci"
      />
      <ContactForm />
    </>
  );
};

export default Contact;
