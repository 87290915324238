import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert, { Color } from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  snackBar: {
    width: '100%',
    marginTop: '10%',
  },
}));

interface Props {
  alertStyle: any,
  message: string,
  open: boolean | undefined
}

const AlertMessage: React.FC<Props> = ({ alertStyle, message, open }) => {
  const classes = useStyles();
  return (
    <Snackbar
      className={classes.snackBar}
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert severity={alertStyle}>{message}</Alert>
    </Snackbar>
  );
};

export default AlertMessage;
